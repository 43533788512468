<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">ປ່ຽນລະຫັດຜ່ານ</h1>
      <div class="columns">
        <div class="column is-6">
          <b-field label="ລະຫັດຜ່ານປະຈຸບັນ" horizontal>
            <b-input
              type="password"
              v-model="currentPassword"
              placeholder="ລະຫັດຜ່ານປະຈຸບັນ"
              required
            />
          </b-field>
          <b-field label="ລະຫັດຜ່ານໃໝ່" horizontal>
            <b-input
              type="password"
              v-model="newPassword"
              placeholder="ລະຫັດຜ່ານໃໝ່"
              required
            />
          </b-field>
          <b-field label="ຢືນຢັນລະຫັດຜ່ານ" horizontal>
            <b-input
              type="password"
              v-model="confirmPassword"
              placeholder="ຢືນຢັນລະຫັດຜ່ານ"
              required
            />
          </b-field>
          <hr />
          <div class="has-text-centered">
            <b-button
              class="button is-primary"
              @click="save()"
              icon-left="check"
            >
              ບັນທຶກ
            </b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
export default {
  name: "ChangePassword",
  data() {
    return {
      currentPassword: null,
      newPassword: null,
      confirmPassword: null,
    };
  },
  methods: {
    save() {
      if (!this.currentPassword) {
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        swal.fire("ຢືນຢັນລະຫັດຜ່ານບໍ່ກົງກັນ", "", "warning");
        return;
      }
      axios
        .post("profile/password", {
          currentPassword: this.sha512(this.currentPassword),
          newPassword: this.sha512(this.newPassword),
          confirmPassword: this.sha512(this.confirmPassword),
        })
        .then((res) => {
          console.log(res);
          swal.fire("ສຳເລັດ", "", "success");
        })
        .catch((e) => {
          swal.fire(e, "", "error");
        });
    },
  },
};
</script>
